import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sprintDates: [],
};

export const sprintDatesSlice = createSlice({
  name: 'sprintDates',
  initialState,
  reducers: {
    getSprintDatesData: (state, action) => {
        state.sprintDatesData = 
[{"sprint":"ES4","start_date":"01-Jul-2024","mid_quarter_date":"12-Aug-2024","end_quarter_date":"07-Oct-2024"},
 {"sprint":"ES5_Jan25","start_date":"07-Oct-2024","mid_quarter_date":"11-Nov-2024","end_quarter_date":"06-Jan-2025"},
 {"sprint":"AS5A_Nov24","start_date":"07-Oct-2024","mid_quarter_date":"N/A","end_quarter_date":"04-Nov-2024"},
 {"sprint":"AS5B_Dec24","start_date":"04-Nov-2024","mid_quarter_date":"N/A","end_quarter_date":"02-Dec-2024"},
 {"sprint":"AS5C_Jan25","start_date":"02-Dec-2024","mid_quarter_date":"N/A","end_quarter_date":"06-Jan-2025"},
 {"sprint":"ES6_Apr25","start_date":"06-Oct-2025","mid_quarter_date":"10-Feb-2025","end_quarter_date":"07-Apr-2025"},
 {"sprint":"AS6A_Feb25","start_date":"06-Oct-2025","mid_quarter_date":"N/A","end_quarter_date":"03-Feb-2025"},
 {"sprint":"AS6B_Mar25","start_date":"03-Feb-2025","mid_quarter_date":"N/A","end_quarter_date":"03-Mar-2025"},
 {"sprint":"AS6C_Apr25","start_date":"03-Mar-2025","mid_quarter_date":"N/A","end_quarter_date":"07-Apr-2025"},
 {"sprint":"ES7_Jul25","start_date":"07-Apr-2025","mid_quarter_date":"19-May-2025","end_quarter_date":"07-Jul-2025"},
 {"sprint":"AS7A_May25","start_date":"07-Apr-2025","mid_quarter_date":"N/A","end_quarter_date":"05-May-2025"},
 {"sprint":"AS7B_Jun25","start_date":"05-May-2025","mid_quarter_date":"N/A","end_quarter_date":"02-Jun-2025"},
 {"sprint":"AS7C_Jul25","start_date":"02-Jun-2025","mid_quarter_date":"N/A","end_quarter_date":"07-Jul-2025"},
 {"sprint":"ES8_Oct25","start_date":"07-Jul-2025","mid_quarter_date":"18-Aug-2025","end_quarter_date":"06-Oct-2025"},
 {"sprint":"AS8A_Aug25","start_date":"07-Jul-2025","mid_quarter_date":"N/A","end_quarter_date":"04-Aug-2025"},
 {"sprint":"AS8B_Sep25","start_date":"04-Aug-2025","mid_quarter_date":"N/A","end_quarter_date":"01-Sep-2025"},
 {"sprint":"AS8C_Oct25","start_date":"01-Sep-2025","mid_quarter_date":"N/A","end_quarter_date":"06-Oct-2025"},
 {"sprint":"ES9_Jan26","start_date":"06-Oct-2025","mid_quarter_date":"17-Nov-2025","end_quarter_date":"05-Jan-2026"},
 {"sprint":"AS9A_Nov25","start_date":"06-Oct-2025","mid_quarter_date":"N/A","end_quarter_date":"03-Nov-2025"},
 {"sprint":"AS9B_Dec25","start_date":"03-Nov-2025","mid_quarter_date":"N/A","end_quarter_date":"01-Dec-2025"},
 {"sprint":"AS9C_Jan26","start_date":"01-Dec-2025","mid_quarter_date":"N/A","end_quarter_date":"05-Jan-2026"},
 {"sprint":"Non-sprint Release","start_date":"N/A","mid_quarter_date":"N/A","end_quarter_date":"N/A"},
]
    },
  },
});

export const { getSprintDatesData } = sprintDatesSlice.actions;
export const selectSprintDatesData = (state) => state.sprintDates.sprintDatesData;

export default sprintDatesSlice.reducer;
