import React, { useEffect,useState } from "react";
import store from '../../redux/store';
import { useFormikContext, Field, setIn } from "formik";
import { LdsSelect, LdsValidationError } from "@elilillyco/ux-lds-react";
import { selectServiceCatalogData, getLoadingState as getServiceCatalogLoadingState, setServiceCatalogData } from "../../redux/slices/serviceCatalogSlice";
import { selectMlData, getLoadingState as getMetricsLibraryLoadingState } from '../../redux/slices/mlSlice';
import { useSelector } from "react-redux";
import { OrcaFormLabelOverrides, OrcaFormTooltipOverrides } from "./OrcaFormLabelOverrides";
import { OrcaFormOptions } from "./OrcaFormOptions";

export const OrcaSelect = ({ name, label, options, required, ...rest }) => {
    const { values, handleChange, touched, errors, setFieldValue } = useFormikContext();
    let displayLabel = OrcaFormTooltipOverrides[name] || OrcaFormLabelOverrides[name] || label || '';
    if (required) {
        displayLabel = <>{displayLabel} <span title='Required' style={{cursor: 'help', color: 'red'}} className="required-label">*</span></>;
    }
    options = options || OrcaFormOptions[name] || [{ value: "", label: "" }];
    // if we have a value that is not valid, just reset it to the first option
    values[name] = values[name]?.toLowerCase() || options[0]?.value.toLowerCase();
    const flatOptions = options.map((option) => {
        return option['value']?.toLowerCase();
    })
    //only check for invalid value when options is a real list of options (not default option list)
    //checking with default option list will cause current value to be wrongly set to ""
    if (flatOptions.length > 1 && flatOptions[0]?.value !== "") {
        if (!flatOptions.includes(values[name]?.toLowerCase())) {
            values[name] = options[0].value.toLowerCase();
            setFieldValue(name, options[0].value.toLowerCase());
        }
    }

    //make incoming options and values lowercase -- case insensitive value fields
    //values[name] = values[name]?.toLowerCase() || options[0].value;
    const newoptions = options.map((option) => {
        return { id: option.value?.toLowerCase(), value: option.value?.toLowerCase(), label: option.label }
    });

    return (
        <div className="lds-select-wrapper" style={{display: 'flex', flexDirection: 'column'}} >
            <label htmlFor={name}  className="lds-form-label">{displayLabel}</label>
            <div className="lds-select-input-wrapper">
                <Field as="select" name={name} label={label} error={errors[name] && touched[name]} className="lds-select" required={rest?.required || false} {...rest}>
                    {options.map((option, key) => {
                        //truncate option labels if label text is too long
                        if (option.label.toString().length > 75) {
                            return <option title={option.label} key={key} value={option.value?.toLowerCase()}>{option.label.toString().substring(0, 75) + '...'}</option>
                        } else {
                            return <option key={key} value={option.value?.toLowerCase()}>{option.label}</option>
                        }
                    })}
                </Field>
            </div>
            { errors[name] && touched[name] && <LdsValidationError id={"err-" + name}>{errors[name]}</LdsValidationError> }
        </div>
    )
}

export const OrcaEnhancementSelect = ({ name, label, options, ...rest }) => {
    const { values: {effort_enhancement_request, effort_granular_request}, handleChange, errors, touched, setFieldValue } = useFormikContext();
    let [ enhancmentOptions, setEnhancementOptions ] = React.useState([{ value: "", label: "" },]);
    let serviceCatalogData = useSelector(selectServiceCatalogData);
    let serviceCatalogLoadingState = useSelector(getServiceCatalogLoadingState);
    useEffect(() => {
        const serviceCatalogChoices = serviceCatalogData.map((option) => {
            return {value: option.enhancement_request, label: option.enhancement_request}
        });
        const uniqServiceCatalogChoices = [...new Map(serviceCatalogChoices.map(item => [item['value'], item])).values()]
        setEnhancementOptions(uniqServiceCatalogChoices);
    }, [serviceCatalogData])

    return (
        <OrcaSelect id={name} name={name} title={effort_enhancement_request} label={label} options={enhancmentOptions} {...rest} />
    );
}

export const OrcaGranularSelect = ({ name, label, options, ...rest }) => {
    const { values: {effort_granular_request, effort_enhancement_request}, values, handleChange, errors, touched, setFieldValue } = useFormikContext();
    let [ granularOptions, setGranularOptions ] = React.useState([{ value: "", label: "" },]);
    let serviceCatalogData = useSelector(selectServiceCatalogData);
    let serviceCatalogLoadingState = useSelector(getServiceCatalogLoadingState);
    useEffect(() => {
        let granulars = serviceCatalogData.filter((option) => {
            return option.enhancement_request.toLowerCase() === effort_enhancement_request.toLowerCase();
        });
        if (granulars.length > 0) {
            setGranularOptions(granulars.map((option) => {
                return {value: option.granular_request, label: option.granular_request}
            }
            ));
        }
    }, [effort_enhancement_request])

    useEffect(() => {
        if (granularOptions?.length > 0) {
            if (granularOptions.findIndex(obj => obj.value?.toLowerCase() === values[name]?.toLowerCase()) === -1) {
                setFieldValue('effort_granular_request', granularOptions[0]?.value?.toLowerCase());
            } else {
                setFieldValue('effort_granular_request', values[name]?.toLowerCase());
            }
        }
    }, [granularOptions])

    return (
        <OrcaSelect id={name} name={name} title={effort_granular_request} label={label} options={granularOptions} {...rest} />
    );
}
export const OrcaIndicationSelect = ({ name, label, formlink, map, ...rest }) => {
    const { values, handleChange, errors, touched, setFieldValue } = useFormikContext();
    let brand = values[formlink]
    let [ indicationOptions, setIndicationOptions ] = React.useState(map[brand] || [{ value: "", label: "" }]);
    useEffect(() => {
        if (map[brand]?.length) {
            const findSelectedIndication = map[brand]?.findIndex((item) => item.value.toLowerCase() === values?.indication)
            if (findSelectedIndication === -1) {
                const indicationValue = map[brand][0].value.toLowerCase();
                values.indication = indicationValue;
                setFieldValue('indication', indicationValue);
            }
        } else {
            setFieldValue('indication', '');
        }
        setIndicationOptions(map[brand] || [{ value: "", label: "" },]);
    }, [brand])

    return (
        <OrcaSelect id={name} name={name} label={label} options={indicationOptions} {...rest} />
    );
}

export const OrcaMetricSelect = ({ name, label, formlink, ...rest }) => {
    const { values, handleChange, errors, touched, setFieldValue } = useFormikContext();
    let [ metricOptions, setMetricOptions ] = React.useState([{ value: "", label: "Select a metric" },]);
    let metricLibraryData = useSelector(selectMlData)
    useEffect(() => {
        if (metricLibraryData.length != 0) {
            let options = metricLibraryData.filter((row) => {
                return row.insight_graph_metric_key != null && row.insight_graph_metric_key != '';
            });
            setMetricOptions(options.map((option) => {
                return {value: option.insight_graph_metric_key, label: option.insight_graph_metric_key}
            }
            ));
        }
    }, [metricLibraryData])
    return (
        <OrcaSelect id={name} name={name} label={label} options={metricOptions} {...rest} />
    );
}

export const OrcaHighlightCloudTagSelect = ({ name, label, formlink, ...rest }) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        // Default options
        const defaultOptions = [
            { value: "", label: "Select a value" },
            { value: "yes", label: "Yes" },
            { value: "no", label: "No" }
        ];
        setOptions(defaultOptions);
    }, []);

    return (
        <OrcaSelect id={name} name={name} label={label} options={options} {...rest} />
    );
}
