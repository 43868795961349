import React, { useEffect,useState } from 'react';
import { useSelector } from "react-redux";
import { selectRequestsData, getRequestsData, getLoadingState,deleteRequestData } from '../../redux/slices/requestsSlice'; 
import { OrcaTable } from '../../Components/common/OrcaTable';
import store from '../../redux/store';
import { LdsButton, LdsModal, useLdsModal } from '@elilillyco/ux-lds-react';
import { selectUserData } from "../../redux/slices/userSlice";

const Requests = () => {
  const data = useSelector(selectRequestsData);
  const loading = useSelector(getLoadingState);
  const { isModalOpen, setIsModalOpen } = useLdsModal();
  const [ modalHeading, setModalHeading ] = useState('Delete Request');
  const [ formData, setFormData ] = useState({});
  const userData = useSelector(selectUserData);

  useEffect(() => {
    store.dispatch(getRequestsData());
  }, []);

  const actionButtons = [
    {
      iconName: 'TrashSimpleFill',
      iconLabel: 'Delete',
      onClick: (row) => {
        let newrow = { ...row };
        setFormData(newrow);
        setModalHeading('Delete Request');
        setIsModalOpen(true);
      },
      isVisible: (userRole, row) => {return (userRole === 'Requester' || userRole === 'Admin') && row['requester'] === userData.name && row.status === 'Draft'},
    }
  ];

  const DeleteRequestForm = ({ formData }) => {

    return (
      <>
        <div className='box'>
        {"Are you sure you want to delete \""}{formData.request_key}{"\"?"}
        </div>
        { <LdsButton id="yesButton" value="delete" label="Delete" name="submit" style={{marginBottom:'10px', marginTop:'20px'}} onClick={() => deleteSubmit(formData)} type='submit'>Delete</LdsButton> }
        { <LdsButton id="noButton" value="cancel" label="Cancel" name="submit" style={{marginBottom:'10px', marginTop:'20px', float: 'right'}} onClick={() => setIsModalOpen(false)} type='submit'>Cancel</LdsButton> }
      </>
    );
  }

  const deleteSubmit = (formData) => {
    store.dispatch(deleteRequestData(formData));
    setIsModalOpen(false);
  };

  return (
    <>
      <LdsModal
        modalId="deleteRequestModal"
        open={isModalOpen}
        setModalOpen={setIsModalOpen}
        heading={modalHeading || "Delete Request"}
        persistent
      >
        <LdsButton
          clearDefaultClasses
          className="lds-button-clear-style lds-modal-close-btn focus icon"
          onClick={() => setIsModalOpen(false)}
          icon="X"
          iconOnly
        />
        {modalHeading === 'Delete Request' && <DeleteRequestForm formData={formData}/>}
      </LdsModal>
      <OrcaTable loading={loading} showFilters rows={data} actionButtons={actionButtons} editModal viewModal title='Enhancement Requests' displayKey={{ 'request_key': { 'display': true }, 'requester': { 'display': true, 'minWidth': '100px' }, 'business_unit': { 'display': true }, 'request_brand': { 'display': true, 'minWidth': '110px'}, "requested_sprint": { 'display': true }, "es_sprint_release_type": { 'display': true, 'minWidth': '250px' }, 'title': { 'display': true}, 'description': { 'display': true, 'shorten': true, 'minWidth': '250px'}, 'bv_calc_score': { 'display': true, 'minWidth': '30px' }, 'cardnumber': { 'display': true}, 'status': { 'display': true, 'title': 'Request Status' }, 'actual_sprint': { 'display': true } }} filterColumns={['request_key', 'requester', 'request_brand', 'brand', 'indication', 'insight_category', 'status']} cloneRequestModal >
      </OrcaTable>
    </>
  );
};

export default Requests;
