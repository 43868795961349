import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { OrcaAPI } from '../../ApiServices/OrcaAPI';
import { React } from 'react';

const initialState = {
  tableData: [],
};

export const cslSlice = createSlice({
  name: 'csl',
  initialState,
  reducers: {
    setCslData: (state, action) => {
        state.tableData = action.payload
    },
    addRequestCIL: (state, action) => {
      action.payload.cardnumber = <a href={'https://lilly-jira.atlassian.net/browse/' + action.payload.cardnumber} style={{ color: 'blue' }} target='_blank' rel='noreferrer'>{action.payload.cardnumber}</a>
      let update = false
      let tableData = state.tableData.map(row => {
        if (row.id === action.payload.id) {
          update = true
          return action.payload;
        }
        return row
      })
      if (!update) {
        state.tableData = [action.payload, ...state.tableData];
      } else {
        state.tableData = [...tableData]
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCslData.pending, (state) => {
        state.tableData = [];
        state.loading = true;
      })
      .addCase(getCslData.fulfilled, (state, action) => {
        state.loading = false;
        let tableData = action.payload.filter((row) => { return row['capability'] && (row['capability'].toLowerCase() === 'br_insight' || row['capability'].toLowerCase() === 'cloudtag_insight'); });
        state.tableData = tableData.map(row => {
          let newrow = {
            insight_key: row.insight_key,
            cardnumber: <a href={'https://lilly-jira.atlassian.net/browse/' + row.cardnumber} style={{ color: 'blue' }} target='_blank' rel='noreferrer'>{row.cardnumber}</a>,
            status: row.status,
            ...row,
          }
          //delete newrow.request_key;
          return newrow;
        });
        state.tableData = state.tableData.sort((a, b) => { return Number(b.id) - Number(a.id)})
      })
      .addCase(getCslData.rejected, (state) => {
        state.loading = false;
        state.tableData = [];
      }).addCase(deleteCslData.pending, (state) => {
        state.loading = true;
      }).addCase(deleteCslData.fulfilled, (state, action) => {
        state.loading = false;
        state.tableData = state.tableData.filter((row) => { return row.id !== action.payload.id; });
      }).addCase(deleteCslData.rejected, (state) => {
        state.loading = false;
      }).addCase(updateCslData.pending, (state) => {
        state.loading = true;
      }).addCase(updateCslData.fulfilled, (state, action) => {
        state.loading = false;
        state.tableData = state.tableData.map((row) => {
          if (row.id === action.payload.id) {
            return action.payload;
          }
          return row;
        });
      }).addCase(updateCslData.rejected, (state) => {
        state.loading = false;
      });
  }
});

export const getCslData = createAsyncThunk('requests/getCslData', async () => {
  const response = await OrcaAPI({ uri: "/submit_request", method: "GET" });
  return response.data;
});

export const deleteCslData = createAsyncThunk('requests/deleteCslData', async (data) => {
  data = { ...data, action: 'delete' };
  const response = await OrcaAPI({ uri: "/consolidated_sugg_lib", method: "PUT", body: data });
  if (response.statusCode === 200) {
    return data;
  }
  return null;
});

export const updateCslData = createAsyncThunk('requests/updateCslData', async (data) => {
  data = { ...data, action: 'save'}
  try {
    const response = await OrcaAPI({ uri: "/cli_admin", method: "PUT", body: data });
    if (response.statusCode === 200) {
      return data;
    }
  } catch (error) {
    return null;
}}
);

export const { setCslData, addRequestCIL  } = cslSlice.actions;
export const selectCslData = (state) => state.csl.tableData;
export const getLoadingState = (state) => state.csl.loading;

export default cslSlice.reducer;
